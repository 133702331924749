import { AfterContentChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin } from 'rxjs';
import { AlertDialogComponent } from 'src/app/Common/alert-dialog-component/alert-dialog-component';
import { Address } from 'src/app/models/leden/address.model';
import { Certificate } from 'src/app/models/leden/certification.model';
import { ContactInfo } from 'src/app/models/leden/contactInfo.model';
import { Exemption } from 'src/app/models/leden/exemption.model';
import { Member } from 'src/app/models/leden/member.model';
import { ExemptionService } from 'src/app/service/exemption.service';
import { CertificateService } from 'src/app/service/Leden/certificate.service';
import { LedenService } from 'src/app/service/Leden/leden.service';
import { AddCertificate } from './add-certificate/add-certificate.component';
import { ImportMembersComponent } from './import-members/import-members.component';

@Component({
  selector: 'app-leden',
  templateUrl: './leden.component.html',
  styleUrls: ['./leden.component.css']
})
export class LedenComponent implements OnInit, AfterContentChecked {
  genders = [{name: 'Man', value: 'm'}, {name: 'Vrouw', value: 'v'}, {name: 'Anders', value: 'a'}];
  details: Member[];
  selectedRow: number;
  openSideDetails = false;
  displayedColumns: string[] = [ 'firstname', 'lastname', 'saldo', 'symbol'];
  dataSource = new MatTableDataSource<Member>();
  ledenData: Array<Member>;
  exemptions: Array<Exemption>;
  exemptionTotal: number ;
  memberForm: FormGroup;
  addressForm: FormGroup;
  member: Member;
  addressFormArray: FormArray;
  address: Address;
  addressess: Address[];
  contactInfo: ContactInfo;
  type: string;
  organisationId: string;
  certificates: Certificate[];
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private changeDetector: ChangeDetectorRef, private ledenService: LedenService,
              private exemptionService: ExemptionService, private formBuilder: FormBuilder, private dialog: MatDialog,
              private cerfService: CertificateService) {
                this.ledenData = new Array<Member>();
                this.exemptions = new Array<Exemption>();
                this.exemptionTotal = 0 ;
                this.addressess = new Array<Address>();
                this.contactInfo = new ContactInfo();
                this.address = new Address();
                this.member = new Member();
  }
  ngOnInit() {
  this.getData();
  this.createMemberForm();
  // this.getExemption();

  this.getCertificates();
  // this.deleteMember('f7cae04c-b88c-4ca9-8d2b-2a2679f652fb');
}

// eslint-disable-next-line use-lifecycle-interface
ngAfterViewInit() {
  this.dataSource.paginator = this.paginator;
}
createMemberForm() {
  this.memberForm = this.formBuilder.group({
    id : new FormControl(),
    organisationId : new FormControl(),
    firstName : new FormControl(null,Validators.required),
    lastName : new FormControl(null,Validators.required),
    birthday : new FormControl(),
    gender : new FormControl(),
    age : new FormControl(),
    contactInfo : this.formBuilder.group([this.ContactInfo]),
    addresses : this.formBuilder.array([this.Address]),
    certifications : this.formBuilder.array([]),
    emailAddress: new FormControl(null,Validators.required)
  });
}

get Certification(): FormGroup {
  return this.formBuilder.group({
      id : new FormControl(),
      expirationDate : new FormControl(),
      certificate : new FormControl(this.Certificate),
      holderId : new FormControl(),
  });
}
get Certificate(): FormGroup {
  return this.formBuilder.group({
    id : new FormControl(),
    name : new FormControl(),
  });
  }
get ContactInfo(): FormGroup {
  return this.formBuilder.group({
    id : new FormControl(),
    contactThroughParents : new FormControl(),
    phoneNumbers :  this.formBuilder.array([this.PhoneNumber]),
    emailAddresses : this.formBuilder.array([this.EmailAddress]),
    parents :  this.formBuilder.array([this.Parent]),
  });
}
get PhoneNumber(): FormGroup {
  return this.formBuilder.group({
    id : new FormControl(),
    number : new FormControl(),
  });
}
get EmailAddress(): FormGroup {
  return this.formBuilder.group({
    id : new FormControl(),
    address : new FormControl(),
  });
}
get Parent(): FormGroup {
  return this.formBuilder.group({
    id : new FormControl(),
    address : new FormControl(),
    phoneNumbers :  this.formBuilder.array([this.PhoneNumber]),
    emailAddresses : this.formBuilder.array([this.EmailAddress]),
  });
}
get Address(): FormGroup {
  return this.formBuilder.group({
    id : new FormControl(),
    postalCode :  new FormControl(),
    houseNumber :  new FormControl(),
    houseNumberAddition :  new FormControl(),
    streetName :  new FormControl(),
    place :  new FormControl(),
    country :  new FormControl(),
  });
}

get emailAddress() { return this.memberForm.get('emailAddress'); }
get firstName() { return this.memberForm.get('firstName'); }
get lastName() { return this.memberForm.get('lastName'); }
/**
 * Get Method to retrive members data
 */
getData() {
  this.ledenService.getData().subscribe((resp: Member[]) => {
  if (resp) {
    this.ledenData = [];
    resp.map(data => {
      this.ledenData.push(data);
    });
    this.dataSource.data = this.ledenData;
  }
});
}

 /* Get All Exeptions */
getExemption() {
  this.exemptionService.getExemptions().subscribe((resp: Exemption[]) => {
    if (resp) {
      resp.map(data => {
        this.exemptions.push(data);
        this.exemptionTotal +=  +data.points ;
      });
    }
  });
}
/**
 * Called when click on add new.
 */
addMember() {
  this.memberForm.reset();
  this.member = new Member();
  this.type = 'ADD';
  this.details = [];
  this.member.addresses.push(this.address);
  this.details.push(this.member);
  this.openSideDetails = true;
  this.changeDetector.detectChanges();
}
/**
 * Form Submit method.
 * Handle Post and Put.
 */
onSubmit(): void {
  if (this.memberForm.invalid) {
    return;
  }

  if (this.type === 'ADD') {
    this.member = new Member();
    this.member.firstName = this.memberForm.controls.firstName.value;
    this.member.lastName = this.memberForm.controls.lastName.value;
    this.member.age = this.memberForm.controls.age.value;
    this.member.gender = this.memberForm.controls.gender.value;
    this.member.organisationId = localStorage.getItem("OrganisationId"); // Fixed
    this.member.birthday = new Date();
    this.memberForm.controls.addresses.value.map(address => {
      this.address = new Address();
      this.address.country = address.country;
      this.address.houseNumber = address.houseNumber;
      this.address.houseNumberAddition = address.houseNumberAddition;
      this.address.place = address.place;
      this.address.postalCode = address.postalCode;
      this.address.streetName = address.streetName;
      this.member.addresses.push(this.address);
    });

    const contactInformation = new ContactInfo();
    contactInformation.emailAddresses.push({
      address : this.memberForm.controls.emailAddress.value
    } );
    this.member.contactInfo = contactInformation;
    this.member.AccountName = this.memberForm.controls.emailAddress.value;
    this.ledenService.addMember(this.member)
    .subscribe((resp: any) => {
      if (resp !== null) {
        this.ledenData.push(resp);
        this.dataSource.data = this.ledenData;
      }
      this.CloseUserDetails();
    });
  } else {
    const id = this.memberForm.value.id;

    // Object.assign(this.member,this.memberForm.value)
    // this.ledenService.updateMember(id,this.member).subscribe(
    //   success =>{
    //     Object.assign(this.ledenData.find(o => o.id === id),success)
    //     this.dataSource.data = this.ledenData;
    //     this.CloseUserDetails();
    //   },
    //   error =>{
    //     console.log(error);
    //   });
    const member = {
        firstName: this.memberForm.controls.firstName.value,
        lastName : this.memberForm.controls.lastName.value,
        birthday: this.memberForm.controls.birthday.value,
        dateOfDeath : null,
        gender: this.memberForm.controls.gender.value,
//        "id" : this.memberForm.controls.id.value,
//          "orgenazationId":this.memberForm.controls.orgenazationId.value
      };

    const memberService$ = this.ledenService.updateMember(id, member);
    const updateMember$ = this.memberForm.controls.addresses.value.map(_address => {
      return this.ledenService.updateAddress(id, _address.id, _address);
    });
    updateMember$.push(memberService$);
    forkJoin(updateMember$)
    .subscribe(resp => {
      if (resp) {
        this.dataSource.data = [];
        this.getData();
        this.CloseUserDetails();
      }
    });

  }

}

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getDetails(idd, numberr) {
    this.type = 'EDIT';
    this.details = 	this.ledenData.filter(x => x.id === idd);
    if (this.details.length > 0) {
      /**
			 *  For filtered details mapping values to formcontrol
       */
    this.details.forEach(element => {
        if (element.addresses.length > 0) {
        // Clear Privious addresses array
        (this.memberForm.get('addresses') as FormArray).clear();
        // Add Address Form Group for available addresses
        element.addresses.map(() => {
          (this.memberForm.get('addresses') as FormArray).push(this.Address);
        });
        }
        if (element.certifications.length > 0) {
        (this.memberForm.get('certifications') as FormArray).clear();
        element.certifications.map(() => {
          (this.memberForm.get('certifications') as FormArray).push(this.Certification);
        });
      }
        this.memberForm.patchValue(element);
        if (this.details[0].contactInfo.emailAddresses[0]) {
          this.memberForm.controls.emailAddress.setValue(this.details[0].contactInfo.emailAddresses[0].address);
        }
        Object.assign(this.member, this.details[0]);
      });
    this.selectedRow = numberr;
    this.openSideDetails = true;
    }
    this.changeDetector.detectChanges();
  }

  CloseUserDetails() {
      this.openSideDetails = false;
      this.selectedRow = -1;
      this.changeDetector.detectChanges();
  }

  ngAfterContentChecked() {
    this.changeDetector.detectChanges();
  }

  deleteMember(id: string) {
    this.dialog.open(AlertDialogComponent, {
      data: {
        message: 'Een lid kan niet worden verwijderd als er nog activiteiten zijn gekoppeld.',
        buttonText: {
          cancel: 'Okay!'
        }
      },
    });
    this.CloseUserDetails();
  }
  getControls() {
    return (this.memberForm.get('addresses') as FormArray).controls;
  }

  getCertificates() {
    this.cerfService.findAll().subscribe((resp: Certificate[]) => {
      if (resp) {
        this.certificates = resp;
      }
    });
  }
  openCertificateDialog(): void {
    const dialogRef = this.dialog.open(AddCertificate, {
      width: '650px',
      data: {id: this.memberForm.value.id, certificates: this.certificates}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ledenService.addMemberCertificate(result.holderId, result).subscribe((resp: any) => {
          this.ledenData.find(x => x.id === resp.id).certifications = resp.certifications;
          this.details.find(x => x.id === resp.id).certifications = resp.certifications;
        //  Object.assign(this.ledenData.find(x => x.id == resp.id,resp))
         // Object.assign(this.details.find(x => x.id == resp.id,resp))
        });
      }
      console.log('The dialog was closed');
    });
  }
  /**
   * Import Members
   */
  importMembers() {
    this.openImportDialog();
  }
  openImportDialog(): void {
    const dialogRef = this.dialog.open(ImportMembersComponent, {
      width: '650px',
      data: {id: this.memberForm.value.id, certificates: this.certificates}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getData();
    });
  }
}
