import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccountService } from 'src/app/service/Account/account.service';
import { SelectOrganisationComponent } from './select-organisation/select-organisation.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  Organisations: string[] = [];
  constructor(private accountServ: AccountService, private dialog: MatDialog) {}

  ngOnInit() {
    this.getOrganisations();
  }

  getOrganisations(): void {
    this.accountServ.getAccountData().subscribe((async resp => {
      if (resp) {
        console.log('resp: ', resp);
        if (resp.members.length === 1) {
          localStorage.setItem('OrganisationId', resp.members[0].organisation.id);
        } else if (resp.members.length === 0) {
          alert('Dit account is niet aan een organisatie gekoppeld');
        } else {
          await resp.members.map(((member: any) => {
            const organisation = member.organisation;
            this.Organisations.push(organisation);
          }));
          // if user is member of multiple organisations show dialog
          this.openSelectOrganisationDialog();
        }
      }
    }));
  }

  openSelectOrganisationDialog(): void {
    const dialogRef = this.dialog.open(SelectOrganisationComponent, {
      width: '650px',
      data: {Organisations: this.Organisations}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        localStorage.setItem('OrganisationId', result.id);
      }
    });
  }
}
