<app-main-nav>
	<h2 class="page-title" (click)="addPlanning()">Planning 	
		<i class="fas fa-plus-circle bg-blue"></i>
	</h2>
	<div class="planning">
		<div  [className]="viewEvent == true ? 'width-7' : 'calendar width-10'">
			<full-calendar #fullcalendar deepChangeDetection="true" [options]="calendarOptions"></full-calendar>
		</div>
		  <div *ngIf="viewEvent" class="width-3 event_info">
			    <div class="userDetail">
			      <button (click)="viewEvent = false" class="close">Sluiten</button>
			      <div class="userName commonFlex">
			        <h2>Activiteit </h2>
			        <!-- <div class="userScore">
			        	<i class="fas fa-star icons-mar"></i>
			        	+
			        	<strong>530</strong> 
			        </div> -->
				  </div>
				  <div *ngIf="selectedActivity && selectedActivity.isCancelled"><h3>Deze activiteit is gecanceld</h3></div>
			      <form [formGroup]="planningForm"  class="form" (ngSubmit)="onSubmit()">
			        <div class="userUpperDetail commonFlex">
			          <div class="user_leftDetail">
						<mat-form-field class="userStreet commonField" appearance="fill">
							<mat-label>Startdatum</mat-label>
							<input matInput [ngxMatDatetimePicker] = "picker"  formControlName="time" placeholder = "Start Date">
							<mat-datepicker-toggle matSuffix [for] = "picker"></mat-datepicker-toggle>
							<ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
						 </mat-form-field>
						<mat-form-field class="userStreet commonField">
							<mat-label>Taak</mat-label>
							<mat-select formControlName="taskId" >
							  <mat-option *ngFor="let _item of tasks" [value]="_item.id"  aria-selected="true">
								{{_item.name}}
							  </mat-option>
							</mat-select>
						  </mat-form-field>
							<!-- <mat-form-field class="userStreet commonField">
								<mat-label>Vereiste certificaat</mat-label>
								<mat-select formControlName="certificateRequirement" >
									<mat-option *ngFor="let _certificate of certificates" [value]="_certificate.id"  aria-selected="true">
									{{_certificate.name}}
									</mat-option>
								</mat-select>
							</mat-form-field> -->
						  <mat-form-field class="userStreet commonField">
							<textarea matInput
							placeholder="Omschrijving" formControlName="description"
							>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
						   </textarea>
						  </mat-form-field>
						  <div>
							 <div *ngIf="selectedActivity">
								<h3 *ngIf="selectedActivity.slots.length > 0">Toegewijzen leden</h3>
								<div *ngFor="let slot of selectedActivity.slots; let i = index">
									<p *ngIf="slot.assignedTo"> {{slot.assignedTo.firstName}} {{slot.assignedTo.lastName}} <mat-icon class="task-assigned-to-icon"(click)="unassignMember(selectedActivity.id, slot.id)" aria-hidden="false" aria-label="">close</mat-icon></p>
									<!-- <p *ngIf="!slot.assignedTo">Open slot {{i+1}}</p> -->
								  </div>
							 </div>
						  </div>
						  <mat-form-field class="userStreet commonField">
							<mat-select [formControl]="memberCtrl" placeholder="toewijzen aan lid"  #singleSelect>
							  <mat-option>
								<ngx-mat-select-search  placeholderLabel="Zoeken..."
								noEntriesFoundLabel="'no matching member found'" [formControl]="memberFilterCtrl"></ngx-mat-select-search>
							  </mat-option>
							  <mat-option *ngFor="let member of filteredMembers | async" [value]="member">
								{{member.firstName}} {{member.lastName}}
							  </mat-option>
							</mat-select>
						  </mat-form-field>
			          </div>
					</div>
					<button type="submit" class="btn btn-primary"><i class="material-icons icons-mar" >save</i> Opslaan</button>
					<button *ngIf="type ===  'Edit' && selectedActivity && !selectedActivity.isCancelled" (click)="cancelActivity(selectedActivity.id)" type="button" class="btn btn-danger"><i class="material-icons icons-mar"  >delete</i> Cancel</button>
				</form>
				<br><hr><br>
				<h3>Leden uitnodigen</h3>
				<div class="invites">
					<mat-form-field class="userStreet commonField" *ngIf="type==='Edit'">
						<mat-select [formControl]="inviteMemberMultiCtrl" multiple="true" placeholder="Leden selecteren"  #multiSelect>
							<mat-option>
							<ngx-mat-select-search  placeholderLabel="Zoeken..."
							noEntriesFoundLabel="'no matching member found'" [formControl]="memberFilterCtrl"></ngx-mat-select-search>
							</mat-option>
							<mat-option *ngFor="let member of filteredMembers | async" [value]="member.id">
							{{member.firstName}} {{member.lastName}}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<button (click)="inviteMembers()" class="btn btn-primary"><i class="material-icons icons-mar" >save</i> Uitnodigen versturen</button>
				</div>
			</div>
		</div>
	</div>
</app-main-nav>
