import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Member } from 'src/app/models/leden/member.model';
import { Team } from 'src/app/models/teams/teams.model';
import { LedenService } from 'src/app/service/Leden/leden.service';
import { TeamsService } from 'src/app/service/Teams/teams.service';
import { AddTeamMemberComponent } from './add-team-member/add-team-member.component';
import { AddTeamComponent } from './add-team/add-team.component';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.css']
})
export class TeamsComponent implements OnInit {

  details: any;
  selectedRow: number;
  openSideDetails = false;
  displayedColumns: string[] = [ 'name', 'NumberOfLeaders', 'NumberOfMembers'];
  dataSource = new MatTableDataSource<Team>();
  editTeam = false;
  editTeamNewName: string;
  selectedTeam: Team;
  teams = Array<Team>();
  members = [];
  teamMembers = [];
  teamLeaders = [];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

constructor(private changeDetector: ChangeDetectorRef, private teamsService: TeamsService,
            private dialog: MatDialog, private memberService: LedenService) {
  this.dataSource.paginator = this.paginator;
}

ngOnInit() {
  this.getTeams();
  this.getMembers();
}

getTeams() {
  this.teamsService.findAll().subscribe((teams: Team[]) => {
    if (teams.length > 0) {
        this.teams = [];
        this.teams = teams;
        this.dataSource.data = teams;
    }
  });
}
applyFilter(filterValue: string) {
  this.dataSource.filter = filterValue.trim().toLowerCase();
}

getDetails(id: string, num: number) {
  this.details = 	this.teams.filter(x => x.id === id);
  this.teamMembers = this.members.filter(x => this.details[0].teamMembers.includes(x.id));
  this.teamLeaders = this.members.filter(x => this.details[0].leaders.includes(x.id));
  if (this.details.length > 0) {
    this.selectedTeam = this.details[0];
    this.editTeamNewName = this.selectedTeam.name;
    this.selectedRow = num;
    this.openSideDetails = true;
  }
  this.changeDetector.detectChanges();
}

closeUserDetails() {
  this.openSideDetails = false;
  this.selectedTeam = undefined;
  this.editTeamNewName = undefined;
  this.selectedRow = -1;
  this.changeDetector.detectChanges();
}

// tslint:disable-next-line: use-lifecycle-interface
ngAfterContentChecked() {
  this.changeDetector.detectChanges();
}

setEditTeam() {
  this.editTeam = !this.editTeam;
}

updateTeam() {
  this.teamsService.updateTeam(this.selectedTeam.id, this.editTeamNewName).subscribe(() => this.getTeams());
  this.closeUserDetails();
  this.editTeamNewName = undefined;
  this.editTeam = false;
}

openAddTeamDialog(): void {
  const dialogRef = this.dialog.open(AddTeamComponent, {
    width: '650px',
    data: {}
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      this.teamsService.createTeam(result).subscribe(() => this.getTeams());
      this.closeUserDetails();
    }
  });
}

removeTeam(teamId: string) {
  console.log('teamId: ', teamId);
  this.closeUserDetails();
  this.teamsService.removeTeam(teamId).subscribe(() => this.getTeams());
}


openAddTeamLeaderDialog(): void {
  const dialogRef = this.dialog.open(AddTeamMemberComponent, {
    width: '650px',
    data: {id: this.details[0].id, members: this.members.filter(x => !this.details[0].teamMembers.includes(x.id))}
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      this.teamsService.addTeamLeader(this.details[0].id, `"${result.id}"`).subscribe((resp: any) => {
        this.updateSelectedItem(resp);
      });
      }
  });
}

removeTeamLeader(memberId: string) {
  this.teamsService.removeTeamLeader(this.selectedTeam.id, memberId).subscribe((resp) => {
    this.updateSelectedItem(resp);
  });
}

openAddTeamMemberDialog(): void {
  const dialogRef = this.dialog.open(AddTeamMemberComponent, {
    width: '650px',
    data: {id: this.details[0].id, members: this.members.filter(x => !this.details[0].teamMembers.includes(x.id))}
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      this.teamsService.addTeamMember(this.details[0].id, `"${result.id}"`).subscribe((resp: any) => {
        this.updateSelectedItem(resp);
      });
      }
  });
}

removeTeamMember(memberId: string) {
  this.teamsService.removeTeamMember(this.selectedTeam.id, memberId).subscribe((resp) => {
    this.updateSelectedItem(resp);
  });
}


getMembers() {
  this.memberService.getData().subscribe((members: Member[]) => {
    if (members) {
      this.members = members;
    }
  });
}

updateSelectedItem(resp: any) {
  this.getTeams();
  this.details.find((x: Team) => x.id === resp.id).leaders = resp.leaders;
  this.details.find((x: Team) => x.id === resp.id).teamMembers = resp.teamMembers;
  this.teamMembers = this.members.filter(x => this.details[0].teamMembers.includes(x.id));
  this.teamLeaders = this.members.filter(x => this.details[0].leaders.includes(x.id));
}
}
export interface TeamElement {
  position: number;
  name: string;
  NumberOfMembers: string;
}
