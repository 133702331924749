import { LayoutModule } from '@angular/cdk/layout';
import { NgModule } from '@angular/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { BeheerComponent } from './pages/beheer/beheer.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LedenComponent } from './pages/leden/leden.component';
import { PlanningComponent } from './pages/planning/planning.component';
import { TakenComponent } from './pages/taken/taken.component';
import { MainNavComponent } from './views/main-nav/main-nav.component';
import {MatInputModule} from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatMenuModule} from '@angular/material/menu';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TakenService } from './service/taken.service';
import { AuthInterceptor } from './service/Authinterceptor';
import {DatePipe} from '@angular/common';
import { AlertDialogComponent } from './Common/alert-dialog-component/alert-dialog-component';
import { TeamsComponent } from './pages/teams/teams.component';
import { CertificatesComponent } from './pages/leden/certificates/certificates.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AddCertificate } from './pages/leden/add-certificate/add-certificate.component';
import { AddTeamMemberComponent } from './pages/teams/add-team-member/add-team-member.component';
import { AddTeamComponent } from './pages/teams/add-team/add-team.component';
import { SelectOrganisationComponent } from './pages/dashboard/select-organisation/select-organisation.component';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import { ImportMembersComponent } from './pages/leden/import-members/import-members.component';
import { SpinnerContainerComponent } from './Common/spinner-container/spinner-container.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin
]);
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    LedenComponent,
    TakenComponent,
    PlanningComponent,
    BeheerComponent,
    MainNavComponent,
    AlertDialogComponent,
    TeamsComponent,
    CertificatesComponent,
    AddCertificate,
    AddTeamMemberComponent,
    AddTeamComponent,
    SelectOrganisationComponent,
    ImportMembersComponent,
    SpinnerContainerComponent,
  ],
  entryComponents: [
    AddTeamMemberComponent,
    AlertDialogComponent,
    AddTeamComponent,
    AddCertificate,
    SelectOrganisationComponent
  ],
  imports: [
    BrowserModule,
    FullCalendarModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatPaginatorModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatSelectModule,
    FullCalendarModule,
    HttpClientModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatTabsModule,
    NgxMatSelectSearchModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    TakenService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

