import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';

export abstract class APIService<T, ID> implements CrudOperations<T, ID> {
  protected apiEndpoint: string  = environment.apiEndPoint;
  constructor(protected http: HttpClient, protected endPoint: string) {
    this.apiEndpoint = `${this.apiEndpoint}/${endPoint}`;
  }
    post(t: T): Observable<T> {
      return this.http.post<T>(this.apiEndpoint, t);
    }

    update(id: ID, t: T): Observable<T> {
      return this.http.put<T>(this.apiEndpoint + '/' + id, t, {});
    }

    findOne(id: ID): Observable<T> {
      return this.http.get<T>(this.apiEndpoint + '/' + id);
    }

    findAll(): Observable<T[]> {
      return this.http.get<T[]>(this.apiEndpoint);
    }

    delete(id: ID): Observable<T> {
      return this.http.delete<T>(this.apiEndpoint + '/' + id);
    }

    customAdd(t: any): Observable<any> {
      return this.http.post<any>(this.apiEndpoint, t);
    }
}

export interface CrudOperations<T, ID> {
  post(t: T): Observable<T>;
  post(t: any): Observable<any>;
  update(id: ID, t: T): Observable<T>;
  findOne(id: ID): Observable<T>;
  findAll(): Observable<T[]>;
  delete(id: ID): Observable<any>;
}
