<h1 mat-dialog-title>Import Members</h1>
<div *ngIf="!isImportStarted" mat-dialog-content class="certificate commonField">
    <label class="btn btn.btn-primary">
        <input  type="file" class="file-input upload" (change)="changeListener($event.target.files)">
  </label>
</div>
<div *ngIf="isImportStarted" mat-dialog-content class="certificate">
    <div class="spinner-container">
        <div class="spinner-background">{{importProgress}}%</div>
            <mat-progress-spinner #spinner
                color="primary"
                mode="determinate"
                value="{{importProgress}}">
              </mat-progress-spinner>
            </div>
            <br>
            <app-spinner-container
                color="primary"
                mode="determinate"
                value="{{importProgress}}">
            </app-spinner-container>
</div>
  