<h1 mat-dialog-title>Team aanmaken</h1>
<div mat-dialog-content>
    <mat-form-field class="commonField full-width">
      <input matInput placeholder="Team naam" [formControl]="memberCtrl">
    </mat-form-field>
</div>
<div mat-dialog-actions>
  <button   class="button btn btn-primary"  mat-button [mat-dialog-close]="memberCtrl.value" cdkFocusInitial><i class="far fa-save icons-mar"></i> Opslaan</button>
  <button mat-button (click)="onNoClick()">Annuleren</button>
</div>
