import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../service/auth.service';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  usernameFormControl: FormControl;
  passwordFormControl: FormControl;
  constructor(public auth: AuthService) { }

  ngOnInit() {
    this.usernameFormControl = new FormControl('', [
      Validators.required,
    ]);
    this.passwordFormControl = new FormControl('', [
      Validators.required,
    ]);
  }

  login() {
    // const username = this.usernameFormControl.value;
    // const password = this.passwordFormControl.value;
    this.auth.login();
  }
}
