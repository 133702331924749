import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Member } from 'src/app/models/leden/member.model';

@Component({
  selector: 'app-add-team-member',
  templateUrl: './add-team-member.component.html',
  styleUrls: ['./add-team-member.component.css']
})
export class AddTeamMemberComponent implements OnInit, OnDestroy {
/** list of Members */
protected membersList: Member[];

/** control for the selected Member */
public memberCtrl: FormControl = new FormControl();

/** control for the MatSelect filter keyword */
public memberFilterCtrl: FormControl = new FormControl('');
/** list of Members filtered by search keyword */
public filteredMembers: ReplaySubject<Member[]> = new ReplaySubject<Member[]>(1);

// @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
private singleSelect: MatSelect;
@ViewChild('singleSelect') set content(content: MatSelect) {
    if (content) { // initially setter gets called with undefined
         this.singleSelect = content;
         this.setInitialValue();
    }
 }

 members = [];
  /** Subject that emits when the component has been destroyed. */
protected onDestroy = new Subject<void>();
constructor(
  public dialogRef: MatDialogRef<AddTeamMemberComponent>,
  @Inject(MAT_DIALOG_DATA) public data: DialogData, private formBuilder: FormBuilder,
  private changeDetector: ChangeDetectorRef) {
    this.members = data.members;
  }

onNoClick(): void {
  this.dialogRef.close();
}

ngOnInit() {
   // load the initial Member list
    this.filteredMembers.next(this.members.slice());
    this.memberFilterCtrl.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe(() => {
    this.filterMembers();
  });
}
ngOnDestroy() {
  this.onDestroy.next();
  this.onDestroy.complete();
}

/* Sets the initial value after the filteredMembers are loaded initially*/
protected setInitialValue() {
this.filteredMembers
  .pipe(take(1), takeUntil(this.onDestroy))
  .subscribe(() => {
    // setting the compareWith property to a comparison function
    // triggers initializing the selection according to the initial value of
    // the form control (i.e. _initializeSelection())
    // this needs to be done after the filteredMembers are loaded initially
    // and after the mat-option elements are available
    this.singleSelect.compareWith = (a: Member, b: Member) => a && b && a.id === b.id;
  });
}
protected filterMembers() {
if (!this.members) {
  return;
}
// get the search keyword
let search = this.memberFilterCtrl.value;
if (!search) {
  this.filteredMembers.next(this.members.slice());
  return;
} else {
  search = search.toLowerCase();
}
// filter the Members
this.filteredMembers.next(
  this.members.filter(member => member.firstName.toLowerCase().indexOf(search) > -1 ||  member.lastName.toLowerCase().indexOf(search) > -1)
);
}

}
export interface DialogData {
  id: string;
  memberId: string;
  members: Member[];
}
