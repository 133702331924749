import { Injectable } from '@angular/core';
import { Certificate } from 'src/app/models/leden/certification.model';
import { APIService } from '../api.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CertificateService extends APIService<Certificate, string> {
  constructor(protected http: HttpClient) {
    super(http, 'Certificates');
  }
}
