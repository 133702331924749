import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExemptionService {
  private url: string;
  constructor(private http: HttpClient) {
  this.url = environment.apiEndPoint + '/Exemptions';
  }
// Fetch data from Exemptions API
getExemptions(): Observable<any> {
  let resp: Observable<any> = null;
  resp = this.http.get<any>(this.url);
  return resp;
}
}
