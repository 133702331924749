import { Slot } from './../models/activity/slot.model';
import { Activity } from 'src/app/models/activity/activity.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  private url: string;
  constructor(private http: HttpClient) {
  this.url = environment.apiEndPoint + '/Activities';
  }
  /**
   * Planning GET API
   */
  getActivities(): Observable<any> {
    let resp: Observable<any> = null;
    resp = this.http.get<any>(this.url);
    return resp;
  }

  addActivity(body: any): Observable<any> {
    let resp: Observable<any> = null;
    resp = this.http.post(this.url, body);
    return resp;
  }

  /**
   *
   * @param id : Activity Id
   * @param body : Activity View Model
   */
  updateActivity(id: string, body: any): Observable<any> {
    let resp: Observable<any> = null;
    resp = this.http.put<any>(this.url + '/' + id, body);
    return resp;
  }

  /**
   *
   * @param id : Activity Id
   * @param body : Activity View Model
   */
  cancelActivity(id: string): Observable<any> {
    let resp: Observable<any> = null;
    resp = this.http.post<any>(this.url + '/' + id + '/cancel', {});
    return resp;
  }

  /**
   * Delete Call to delete task
   * @param id Activity ID
   */
  deleteTask(id: string): Observable<any> {
    let resp: Observable<any> = null;
    resp = this.http.delete<any>(`${this.url}/${id}/assign`);
    return resp;
  }

  /**
   *
   * @param activity : Activity Id
   * @param body : Activity View Model
   */
  assignToMember(activity: Activity, memberId: string): Observable<any> {
    if (undefined === memberId) {
      console.log('no memberid found');

      return;
    }
    console.log('memberId: ', memberId);
    // Find free slot
    const openSlot: Slot = activity.slots.find(slot => slot.isAssigned === false);

    if (undefined === openSlot) {
      console.error('No open slots');
      return;
    }

    let resp: Observable<any> = null;
    resp = this.http.post<any>(`${this.url}/${activity.id}/slots/${openSlot.id}/assign`, `"${memberId}"`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return resp;
  }

  /**
   *
   * @param activityId : Activity Id
   * @param slotId : Slot Id
   */
  unassignMember(activityId: string, slotId: string): Observable<any> {
    console.log('activityId: ', activityId);
    let resp: Observable<any> = null;
    resp = this.http.delete<any>(`${this.url}/${activityId}/slots/${slotId}/assign`);
    return resp;
  }

  /**
   *
   * @param id : Activity Id
   * @param body : Activity View Model
   */
  inviteMembers(body: any): Observable<any> {
    let resp: Observable<any> = null;
    resp = this.http.post<any>(`${environment.apiEndPoint}/MassInvitations`, body);
    return resp;
  }
}
