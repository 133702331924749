<h1 mat-dialog-title>Lid selecteren</h1>
<div mat-dialog-content>
    <mat-form-field class="certificate commonField">
        <mat-select [formControl]="memberCtrl" placeholder="Selecteer lid"  #singleSelect>
          <mat-option>
            <ngx-mat-select-search  placeholderLabel="Leden zoeken..." 
            noEntriesFoundLabel="'no matching member found'" [formControl]="memberFilterCtrl"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let member of filteredMembers | async" [value]="member">
            {{member.firstName}} {{member.lastName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
</div>
<div mat-dialog-actions>
  <button   class="button btn btn-primary"  mat-button [mat-dialog-close]="memberCtrl.value" cdkFocusInitial><i class="far fa-save icons-mar"></i> Opslaan</button>
  <button mat-button (click)="onNoClick()">Annuleren</button>
</div>