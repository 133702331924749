import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-select-organisation',
  templateUrl: './select-organisation.component.html',
  styleUrls: ['./select-organisation.component.css']
})
export class SelectOrganisationComponent implements OnInit {

  organisationForm: FormGroup;
  constructor(public dialogRef: MatDialogRef<SelectOrganisationComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.createOrganisationForm();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  createOrganisationForm() {
    this.organisationForm = this.formBuilder.group({
      id : new FormControl('', Validators.required)
    });
  }
}
export interface DialogData {
  Organisations: Orgenization[];
}
interface Orgenization {
  id: string;
  name: string;
}

