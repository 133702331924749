import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Team } from 'src/app/models/teams/teams.model';
import { APIService } from '../api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TeamsService extends APIService<Team, string> {
  public url = environment.apiEndPoint + '/Teams';
  constructor(protected http: HttpClient) {
    super(http, 'Teams');
  }

  /**
   * Create team
   * @param teamId string
   * @param body Task Model
   */
  createTeam(teamName: string): Observable<any> {
    let resp: Observable<any> = null;
    resp = this.http.post(`${this.url}`, {organisationId: this.getOrganisationId(), name: teamName});
    return resp;
  }

  /**
   * Update team
   * @param teamId string
   * @param body Task Model
   */
  updateTeam(teamId: string, teamName: string): Observable<any> {
    let resp: Observable<any> = null;
    resp = this.http.put(`${this.url}/${teamId}`,  {
      id: teamId,
      name: teamName
    });
    return resp;
  }

  /**
   * Remove team
   * @param teamId string
   * @param body Task Model
   */
  removeTeam(teamId: string): Observable<any> {
    let resp: Observable<any> = null;
    resp = this.http.delete(`${this.url}/${teamId}`);
    return resp;
  }

  /**
   * Add user
   * @param teamId string
   * @param body Task Model
   */
  addTeamLeader(teamId: string, body: any): Observable<any> {
    let resp: Observable<any> = null;
    resp = this.http.post(`${this.url}/${teamId}/leaders`, body);
    return resp;
  }

  /**
   * Remove user
   * @param teamId string
   * @param memberID string
   */
  removeTeamLeader(teamId: string, memberID: string): Observable<any> {
    let resp: Observable<any> = null;
    resp = this.http.delete(`${this.url}/${teamId}/leaders/${memberID}`);
    return resp;
  }

  /**
   * Add user
   * @param teamId string
   * @param body Task Model
   */
  addTeamMember(teamId: string, body: any): Observable<any> {
    let resp: Observable<any> = null;
    resp = this.http.post(`${this.url}/${teamId}/members`, body);
    return resp;
  }

  /**
   * Remove user
   * @param teamId string
   * @param memberID string
   */
  removeTeamMember(teamId: string, memberID: string): Observable<any> {
    let resp: Observable<any> = null;
    resp = this.http.delete(`${this.url}/${teamId}/members/${memberID}`);
    return resp;
  }

  /**
   * Fetch OrganisationId of logged in user
   */
  getOrganisationId() {
    return localStorage.getItem('OrganisationId');
  }
}
