<form [formGroup] = "organisationForm" class="form">
<h1 mat-dialog-title>Organisation toevoegen</h1>
<div mat-dialog-content>
        <mat-form-field class="organisation commonField">
            <mat-label>Selecteer Organisation</mat-label>
            <mat-select formControlName="id">
              <mat-option *ngFor="let Organisation of data.Organisations" [value]="Organisation.id" aria-selected="true">
                {{Organisation.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
</div>
<div mat-dialog-actions>
  <button  [disabled]="!organisationForm.valid" class="button btn btn-primary"  mat-button [mat-dialog-close]="organisationForm.value" cdkFocusInitial><i class="far fa-save icons-mar"></i> Opslaan</button>
</div>
</form>