<app-main-nav>

    <h2 class="page-title">Teams
  <i (click)="openAddTeamDialog()" class="fas fa-plus-circle bg-blue"></i>
    </h2>
    <div class="laden commonFlex">
      <div class="mat-elevation-z8" [className]="openSideDetails == true ? 'half-width' : 'full-width'">
        <div class="filtration">
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
          <mat-paginator  [length]="100"  [pageSize]="10" showFirstLastButtons></mat-paginator>
        </div>
      <table mat-table [dataSource]="dataSource">

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Naam </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
        <!-- Leader Count Column -->
        <ng-container matColumnDef="NumberOfLeaders">
          <th mat-header-cell *matHeaderCellDef> Leiders </th>
          <td mat-cell *matCellDef="let element"> {{element.leaders.length}} </td>
        </ng-container>
        <!-- Member Count Column -->
        <ng-container matColumnDef="NumberOfMembers">
          <th mat-header-cell *matHeaderCellDef> Leden </th>
          <td mat-cell *matCellDef="let element"> {{element.teamMembers.length}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row (click)="getDetails(row.id, i)" [class.active]="i == selectedRow"  *matRowDef="let row; columns: displayedColumns; let i = index"></tr>
      </table>
    </div>

    <div *ngIf="openSideDetails" [className]="openSideDetails == true ? 'half-width' : 'full-width'">
      <div class="userDetail" *ngFor="let team of details">
        <button (click)="closeUserDetails()" class="close">Sluiten</button>
        <div class="userName commonFlex">
            <h2> {{team.name}} <i (click)="setEditTeam()" class="material-icons icons-mar">edit</i></h2>
        </div>
        <div [hidden]="!editTeam" class="editTeam">
          <mat-form-field class="commonField full-width">
            <input matInput placeholder="Team naam" [(ngModel)]="editTeamNewName">
          </mat-form-field>
          <button (click)="updateTeam()" class="btn btn-primary"><i class="material-icons icons-mar">save</i> Opslaan</button>
          <br>
          <div class="lowerdetails">
            <button (click)="removeTeam(team.id)" type="button" class="btn btn-danger"><i class="material-icons icons-mar">delete</i> Team verwijderen</button>
          </div>
        </div>
        <br>
        <form class="form">
          <div class="userUpperDetail commonFlex">
            <div class="user_leftDetail">
            <div class="certification">
                <h3>Leiders <i class="fas fa-plus-circle bg-blue" (click)="openAddTeamLeaderDialog()"></i></h3>
                <ul>
                  <li>
                    <span class="width-6">Naam</span>
                    <span class="width-2"></span>
                  </li>
                  <li *ngFor="let leader of teamLeaders"><p>{{leader.firstName}}</p>
                    <p class="width-2"><mat-icon (click)="removeTeamLeader(leader.id)" aria-hidden="false" aria-label="">close</mat-icon></p>
                  </li>
                </ul>

                <h3>Leden <i class="fas fa-plus-circle bg-blue" (click)="openAddTeamMemberDialog()"></i></h3>
                <ul>
                  <li>
                    <span class="width-6">Naam</span>
                    <span class="width-2"></span>
                  </li>
                  <li *ngFor="let member of teamMembers"><p>{{member.firstName}}</p>
                    <p class="width-2"><mat-icon (click)="removeTeamMember(member.id)" aria-hidden="false" aria-label="">close</mat-icon></p>
                  </li>
                </ul>
              </div>
          </div>
        </div>
    </form>

  </div>
  </div>
  </div>
  </app-main-nav>