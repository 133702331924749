import { GUID } from '../guid.model';

export class ContactInfo {
    public id: GUID;
    public contactThroughParents: boolean;
    public phoneNumbers: PhoneNumber[];
    public emailAddresses: EmailAddres[];
    public parents: Parent[];
    public memberId?: string;
    /**
     *
     */
    constructor() {
    this.contactThroughParents = false,
    this.emailAddresses = [],
    this.phoneNumbers = [],
    this.parents = []
    }
}
export class PhoneNumber {
    public id: GUID;
    public number: string;
    public contactInfoId?: string;
    public parentInfoId?: string;
}
export class EmailAddres {
  //  public id: GUID;
    public address: string;
    public contactInfoId?: string;
    public parentInfoId?: string;
}
export class Parent {
    public id: GUID;
    public name: string;
    public contactInfoId?: string;
    public phoneNumbers: PhoneNumber[];
    public emailAddresses: EmailAddres[];
}
