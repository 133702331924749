import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Papa } from 'ngx-papaparse';
import { from, of } from 'rxjs';
import { concatMap, delay } from 'rxjs/operators';
import { Address } from 'src/app/models/leden/address.model';
import { ContactInfo } from 'src/app/models/leden/contactInfo.model';
import { Member } from 'src/app/models/leden/member.model';
import { LedenService } from 'src/app/service/Leden/leden.service';

@Component({
  selector: 'app-add-certificate',
  templateUrl: './import-members.component.html',
  styleUrls: ['./import-members.component.css']
})
export class ImportMembersComponent implements OnInit {
  certificateForm: FormGroup;
  member: Member;
  members: Member[];
  address: Address;
  isImportStarted = false;
  importProgress = 5;
  totalMembers = 0;
  importedMembers = 0;
  constructor(
    public dialogRef: MatDialogRef<ImportMembersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private papa: Papa,
    private ledenService: LedenService) {
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }
  public changeListener(files: FileList){
    if (files && files.length > 0) {
       const file: File = files.item(0);
       this.papa.parse((file), {
        header: true,
        skipEmptyLines: true,
        complete: (result: any) => {
            this.isImportStarted = true;
            this.totalMembers = result.data.length;
            this.CalculateProgress(this.totalMembers, 0);
            this.members = [];
            from(result.data).pipe(
              concatMap( item => of(item).pipe ( delay( 2000 ) ))
              ).subscribe ( timedItem => {
              console.log(timedItem);
              this.PupulateMember(timedItem);
              this.PostMemberData();
          });
        }
    });
    }
  }
  PupulateMember(data: any){
    this.member = new Member();
    this.member.firstName = data.FirstName;
    this.member.lastName = data.LastName;
    this.member.gender = data.Gender;
    this.member.birthday = new Date();
    this.address = new Address();
    this.address.country = data.Country;
    this.address.houseNumber = data.HouseNumber;
    this.address.houseNumberAddition = data.HouseNumberAddition;
    this.address.place = data.Place;
    this.address.postalCode = data.PostalCode;
    this.address.streetName = data.StreetName;
    this.member.addresses.push(this.address);
    const contactInformation = new ContactInfo();
    contactInformation.emailAddresses.push({
                address : data.Email
              } );
    this.member.contactInfo = contactInformation;
    this.member.AccountName = data.Email;
    this.members.push(this.member);
  }

  PostMemberData(){
    this.ledenService.addMember(this.member)
    .subscribe((resp: any) => {
        this.importedMembers += 1;
        this.CalculateProgress(this.totalMembers, this.importedMembers);
    });
  }

  CalculateProgress(totalMembers: number, importedMember: number){
    const progress = parseFloat(((importedMember / totalMembers) * 100).toFixed(0));
    this.importProgress = progress <= 5 ? 5 : progress;
    if (this.importProgress === 100) {
      this.dialogRef.close();
    }
  }
}

export interface DialogData {
  id: string;
}

