import { Component, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
@Component({
  selector: 'app-spinner-container',
  templateUrl: './spinner-container.component.html',
  styleUrls: ['./spinner-container.component.css'],
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    class: 'spinner-container'
  },
  // tslint:disable-next-line: no-inputs-metadata-property
  inputs: ['color', 'diameter', 'mode', 'strokeWidth', 'value'],
})
export class SpinnerContainerComponent {

  @Input() color: ThemePalette;
  @Input() diameter: number;
  @Input() display: string | number;
  @Input() mode: ProgressSpinnerMode;
  @Input() strokeWidth: number;
  @Input() value: number;

}
