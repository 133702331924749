<div class="login-wrapper">
  <div class="center">
    <mat-card class="box">
      <mat-card-header>
        <mat-card-title>Tareas</mat-card-title>
      </mat-card-header>
<br><br>
      <form class="example-form">
        <!-- <mat-card-content>
          <mat-form-field class="example-full-width">
            <input matInput type="name" placeholder="Username" [formControl]="usernameFormControl">
            <mat-error *ngIf = "usernameFormControl.hasError('text') && !usernameFormControl.hasError('required')">
              Please enter a username
            </mat-error>
            <mat-error *ngIf = "usernameFormControl.hasError('required')">
              Username is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <input matInput type="password" placeholder="Password" [formControl]="passwordFormControl">
            <mat-error *ngIf = "passwordFormControl.hasError('text') && !passwordFormControl.hasError('required')">
              Please enter a password
            </mat-error>
            <mat-error *ngIf = "passwordFormControl.hasError('required')">
              Password is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </mat-card-content> -->
        <button mat-stroked-button color="accent" class="btn-block" (click)="login()">Log in</button>
      </form>
    </mat-card>
  </div>
</div>
