import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Member } from '../../models/leden/member.model';
import { Address } from '../../models/leden/address.model';
import { PhoneNumber, EmailAddres } from '../../models/leden/contactInfo.model';

@Injectable({
  providedIn: 'root'
})
/*
@Service
For HTTP calls related to leden component
*/
export class LedenService {
  private url: string;
  constructor(private http: HttpClient) {
  this.url = environment.apiEndPoint + '/Members';
  }
  // Fetch data from members API
  getData(): Observable<any> {
    let resp: Observable<any> = null;
    resp = this.http.get<any>(`${this.url}?organisationId=${localStorage.getItem('OrganisationId')}`);
    return resp;
  }
/**
 *
 * @param id : Member Id
 * @param body : Member Model
 */
updateMember(id: string, body: any): Observable<any> {
  let resp: Observable<any> = null;
  resp = this.http.put<any>(this.url + '/' + id, body);
  return resp;
}
/**
 * Add New Member
 * @param body Member Model
 */
addMember(body: Member): Observable<any> {
  body.organisationId = this.getOrganisationId();
  console.log('body: ', body);
  let resp: Observable<any> = null;
  resp = this.http.post(this.url, body);
  return resp;
}

/**
 * Fetch OrganisationId of logged in user
 */
getOrganisationId() {
  return localStorage.getItem('OrganisationId');
}

/**
 * Fetch Organisations Details
 */
getorganisation(): Observable<any> {
  let resp: Observable<any> = null;
  resp = this.http.get <any>(environment.apiEndPoint + '/Organisations');
  return resp;
}
/**
 * Delete Call to delete member
 * @param id Member ID
 */
deleteMember(id: string): Observable<any> {
  let resp: Observable<any> = null;
  resp = this.http.delete<any>(this.url + '/' + id);
  return resp;
}
/**
 * PUT Method call for update address.
 * @param memberId Member ID
 * @param addressId Address Id
 * @param body Address Model
 */
updateAddress(memberId: string, addressId: string, body: Address) {
  let resp: Observable<any> = null;
  resp = this.http.put<any>(this.url + '/' + memberId + '/addresses/' + addressId, body);
  return resp;
}
/**
 * Update Member's Contact Information.
 * @param id Member Id
 * @param contactThroughParent Boolean value indicated Contact through parent Yes or NO
 */
updateContactInfo(id: string, contactThroughParent: boolean) {
  let resp: Observable<any> = null;
  resp = this.http.put<any>(this.url + '/' + id + '/contactinfo', contactThroughParent);
  return resp;
}
/**
 * Update Members phone number detail
 * @param memberId Member Id
 * @param phoneNumberId Phone number Id
 * @param body Phone Number model
 */
updatePhoneNumber(memberId: string, phoneNumberId: string, body: PhoneNumber) {
  let resp: Observable<any> = null;
  resp = this.http.put<any>(this.url + '/' + memberId + '/phonenumbers/' + phoneNumberId , body);
  return resp;
}
/**
 * Update Member's email details.
 * @param memberId Member Id
 * @param emailAddressId email address id
 * @param body Email model
 */
updateEmailsAddress(memberId: string, emailAddressId: string, body: EmailAddres) {
  let resp: Observable<any> = null;
  resp = this.http.put<any>(this.url + '/' + memberId + '/emailaddresses/' + emailAddressId , body);
  return resp;
}
/**
 * Add New Certificate
 * @param body Member Model
 */
addMemberCertificate(memberId: string, body: any): Observable<any> {
  let resp: Observable<any> = null;
  resp = this.http.post(`${this.url}/${memberId}/certifications`, body);
  return resp;
}

createContact(memberId: string, body: any) {
let resp: Observable<any> = null;
resp = this.http.post(`${this.url}/${memberId}/emailaddresses`, body);
return resp;
}
}
