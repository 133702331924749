import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable()
/**
 * Taken Service to communicate with backend
 */
export class TakenService {

    private url: string;

    constructor(private http: HttpClient) {
        this.url = environment.apiEndPoint + '/Tasks';
    }

  /**
   * TASK GET API
   */
  getData(): Observable<any> {
    let resp: Observable<any> = null;
    resp = this.http.get<any>(this.url);
    return resp;
    }

  /**
   *
   * @param id : Task Id
   * @param body : Task Model
   */
  updateTask(id: string, body: any): Observable<any> {
    let resp: Observable<any> = null;
    resp = this.http.put<any>(this.url + '/' + id, body);
    return resp;
  }
  /**
   * Post Data
   * @param body Task Model
   */
  addTask(body: any): Observable<any> {
    body.organisationId = this.getOrganisationId();
    let resp: Observable<any> = null;
    resp = this.http.post(this.url, body);
    return resp;
  }

  getTaskCategories(): Observable<any> {
    let resp: Observable<any> = null;
    resp = this.http.get<any>(environment.apiEndPoint + '/TaskCategories');
    return resp;
  }

  updateTaskCategory(id, taskId): Observable<any> {
    let resp: Observable<any> = null;
    const headers = new HttpHeaders(
      {'content-type': 'application/json'});
    const tempUrl = this.url + '/' + id + '/category';
    // resp = this.http.put<any>(tempUrl, `"${taskId}"`, {headers: header, params : {taskId: id}});
    resp = this.http.put<any>(tempUrl, `"${taskId}"`, {headers});
    return resp;
  }

  /**
   * Fetch Organisations Details
   */
  getorganisation(): Observable<any> {
    let resp: Observable<any> = null;
    resp = this.http.get <any>(environment.apiEndPoint + '/Organisations');
    return resp;
  }

  /**
   * Delete Call to delete task
   * @param id task ID
   */
  deleteTask(id: string): Observable<any> {
    let resp: Observable<any> = null;
    resp = this.http.delete<any>(this.url + '/' + id);
    return resp;
  }

  /**
   * Fetch OrganisationId of logged in user
   */
  getOrganisationId() {
    return localStorage.getItem('OrganisationId');
  }

}
