import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Certificate } from 'src/app/models/leden/certification.model';

@Component({
  selector: 'app-add-certificate',
  templateUrl: './add-certificate.component.html',
  styleUrls: ['./add-certificate.component.css']
})
export class AddCertificate implements OnInit {
  certificateForm: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<AddCertificate>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,private formBuilder : FormBuilder) {
      this.createCertificateForm();
      this.certificateForm.controls.holderId.setValue(data.id);
      this.certificateForm.controls.id.setValue(data.id);
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    
  }
  createCertificateForm(){
    this.certificateForm = this.formBuilder.group({
      "id" : new FormControl(""),
      "expirationDate" :new FormControl("",Validators.required),
      "certificate" : new FormControl("",Validators.required),
      "holderId" : new FormControl("",Validators.required),
    })
  }
}
export interface DialogData {
  id: string;
  certificates: Array<Certificate>;
}

