import { GUID } from '../guid.model';
import { Address } from './address.model';
import { Certification } from './certification.model';
import { ContactInfo } from './contactInfo.model';

export class Member {
    public id: GUID;
    public organisationId: string;
    public firstName: string;
    public lastName: string;
    public gender: string;
    public birthday: Date;
    public age: number;
    public dateOfDeath: Date;
    public addresses: Address[];
    public contactInfo: ContactInfo;
    public certifications: Certification[];
    public AccountName: string;
    constructor() {
        this.firstName  = '',
        this.lastName = '',
        this.birthday = new Date(),
        this.age  = 0,
        this.addresses  = [],
        this.contactInfo  = new ContactInfo(),
        this.certifications = [];
    }
}
