import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private url: string;
  constructor(private http: HttpClient) {
    this.url = environment.apiEndPoint + '/Accounts';
  }

  // Fetch data from members API
  getAccountData(): Observable<any> {
    let resp: Observable<any> = null;
    resp = this.http.get<any>(`${this.url}/${localStorage.getItem('accountId')}`);
    return resp;
  }
}
