// Components
import { LoginComponent } from './auth/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LedenComponent } from './pages/leden/leden.component';
import { TakenComponent } from './pages/taken/taken.component';
import { PlanningComponent } from './pages/planning/planning.component';
import { BeheerComponent } from './pages/beheer/beheer.component';


// Misc
import { Component } from '@angular/core';
import { Route, CanActivate } from '@angular/router';
import { AuthGuard } from './service/auth.guard';
import { TeamsComponent } from './pages/teams/teams.component';


const indexRoute: Route = {
  path: '',
  component: LoginComponent
};

const fallbackRoute: Route = {
  path: '**',
  component: LoginComponent
};

export const routeConfig = [
  // views
  {
    path: 'home',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'leden',
    component: LedenComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'taken',
    component: TakenComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'planning',
    component: PlanningComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'beheer',
    component: BeheerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'teams',
    component: TeamsComponent,
    canActivate: [AuthGuard]
  },
  fallbackRoute,
    indexRoute
  ];
