export class Address {
    public id: string;
    public postalCode: string;
    public houseNumber: string;
    public houseNumberAddition: string;
    public streetName: string;
    public place: string;
    public country: string;
    public memberId?: string;

    constructor() {
        this.postalCode = '',
        this.houseNumber = '',
        this.houseNumberAddition = '',
        this.streetName = '',
        this.place = '',
        this.country = '';
    }
}
