import { GUID } from '../guid.model';
import { CertificateRequirement } from './certificaterequirement.model';
import { Tags } from './tags.model';
import { TaskCategory } from './taskcategory.model';
import { TaskRequirementSet } from './taskrequirementset.model';
import { TaskState } from './taskstate.model';


export class Task {
    public id: GUID;
    public organisationId: GUID;
    public name: string;
    public minutes: number;
    public defaultTaskValue: number;
    public shortDescription: string;
    public description: string;
    public taskState: TaskState;
    public category: TaskCategory;
    public tags: Tags;
    public taskRequirementSet: TaskRequirementSet;
    public certificateRequirements: CertificateRequirement[];
}
