import { HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    const authToken = this.auth.getAuthorizationToken();
    // console.log('authToken: ', authToken);
    const Organisation = localStorage.getItem('OrganisationId');
    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    const headers = new HttpHeaders({
      Authorization: authToken,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin' : '*',
      // OrganisationId : Organisation !== null ? Organisation : ''
      OrganisationId: Organisation || '<none-found>'
    });
    const authReq = req.clone({headers});

    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }
}
