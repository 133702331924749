import { NgModule } from '@angular/core';
import { routeConfig } from './router-config';
import { Routes, RouterModule, CanActivate } from '@angular/router';


const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forRoot(routeConfig, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
