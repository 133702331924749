<h2 class="page-title" (click)="addCertificate()"> Certificate <i class="fas fa-plus-circle bg-blue"></i>
</h2>
<div class="certificate commonFlex">
    <div class="mat-elevation-z8" [className]="openSideDetails == true ? 'width-7' : 'full-width'">
      <div class="filtration">
       <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter"> 
      <mat-paginator  [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
     </div>
     <table mat-table [dataSource]="dataSource">  
      <!-- Id Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> Id </th>
        <td mat-cell *matCellDef="let certificate"> {{certificate.id}} </td>
      </ng-container>
      <!-- Certificate Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Certificate </th>
        <td mat-cell *matCellDef="let certificate"> {{certificate.name}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row (click)="getDetails(row.id, i)" [class.active]="i == selectedRow"  *matRowDef="let row; columns: displayedColumns; let i = index"></tr>
    </table>       
  </div>
  <div *ngIf="openSideDetails" [className]="openSideDetails == true ? 'width-3' : 'full-width'">
      <div class="userDetail">
        <button (click)="CloseUserDetails()" class="close">Sluiten</button>
        <div class="userName commonFlex">
            <h2> {{type == 'Edit' ? "Certificaat beheren": "Certificaat aanmaken"}} </h2>
          </div>
        <form  class="form"  [formGroup]="certificateForm" class="form"  (ngSubmit)="onSubmit()">
            <div class="userUpperDetail commonFlex">
            <div class="user_leftDetail">
                <mat-form-field class="user_name commonField">
                    <input matInput placeholder="Name"  formControlName="name">
                  </mat-form-field>
            </div>
            </div>
            <button type="submit" class="btn btn-primary"><i class="far fa-save icons-mar"></i> Opslaan</button>
            <div *ngIf="type == 'Edit'" class="lowerdetails"> 
              <button (click)="deleteCertificate()" type="button" class="btn btn-danger"><i class="material-icons icons-mar"  >delete</i> Verwijderen</button>
             </div>
        </form>
      </div>
  </div>

</div>