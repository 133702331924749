import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Member } from 'src/app/models/leden/member.model';

@Component({
  selector: 'app-add-team',
  templateUrl: './add-team.component.html',
  styleUrls: ['./add-team.component.css']
})
export class AddTeamComponent implements OnInit, OnDestroy {
/** control for the selected Member */
public memberCtrl: FormControl = new FormControl();

/** list of Members filtered by search keyword */
public filteredMembers: ReplaySubject<Member[]> = new ReplaySubject<Member[]>(1);

  /** Subject that emits when the component has been destroyed. */
protected onDestroy = new Subject<void>();
constructor(
  public dialogRef: MatDialogRef<AddTeamComponent>,
  @Inject(MAT_DIALOG_DATA) public data: DialogData, private formBuilder: FormBuilder,
  private changeDetector: ChangeDetectorRef
  ) {}

onNoClick(): void {
  this.dialogRef.close();
}

ngOnInit() {}
ngOnDestroy() {
  console.log(this.memberCtrl);
  this.onDestroy.next();
  this.onDestroy.complete();
}

}
export interface DialogData {
  id: string;
  memberId: string;
  members: Member[];
}
