import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-beheer',
  templateUrl: './beheer.component.html',
  styleUrls: ['./beheer.component.css']
})
export class BeheerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
